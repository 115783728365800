@font-face {
    font-family: 'blackout_sunriseregular';
    src: url('blackout_sunrise-webfont.woff2') format('woff2'),
        url('blackout_sunrise-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);

body{
    font-size: 16px;
}

.main.container{
    margin-top: 50px;
    margin-bottom: 50px;
    h1{
        font-family: "blackout_sunriseregular";
        font-weight: 100;
        text-transform: uppercase;
        font-size: 7em;
        &.center{ font-size: 9em}
        padding-left: 40px;
        position: relative;
        img{
            max-width: 200px;
        }
    }
}
.ui.items>.item>.image:not(.ui){
    width: 500px;
}

#cover{
    overflow: hidden;
    position: relative;
    width: 450px;
    height: 636px;
    * {
        box-sizing: border-box;
    }
    img{
        opacity: 1;
        transition: opacity 0.35s;
    }
    & > div{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &::before{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            content: '';
            opacity: 0;
            transition: opacity 0.4s;
            background-image: linear-gradient(-45deg, #000000 0%, transparent 40%, rgba(255, 255, 255, 0.15));
        }
    }
    i {
        display: inline-block;
        font-size: 36px;
        color: #ffffff;
        padding: 6px 16px;
        position: absolute;
        bottom: 0;
        right: 0;
        opacity: 0;
        z-index: 1;
        transition: 0.05 linear;
        transition-delay: 0.01s;
    }
    .curl{
        width: 0px;
        height: 0px;
        position: absolute;
        bottom:0;
        right: 0;
        background: linear-gradient(135deg, #ffffff, #f3f3f3 20%, #bbbbbb 38%, #aaaaaa 44%, #888888 50%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0.3));
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        transition: all .4s ease;
        &::before, &::after{
            content: '';
            position: absolute;
            z-index: -1;
            left: 12%;
            bottom: 6%;
            width: 70%;
            max-width: 300px;
            max-height: 100px;
            height: 55%;
            box-shadow: 0 12px 15px rgba(0, 0, 0, 0.3);
            transform: skew(-10deg) rotate(-6deg);
        }
        &::after{
            left: auto;
            right: 6%;
            bottom: auto;
            top: 14%;
            transform: skew(-15deg) rotate(-84deg);
        }
    }
    a{
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        color: #ffffff;
    }
    &:hover {
        & >div::before {
            opacity: 1;
        }
        i {
            opacity: 0.7;
            transition-delay: 0.15s;
        }
        .curl {
            width: 90px;
            height: 90px;
        }
    }
}
